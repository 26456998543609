<template>
  <div class="vue-top">
    <div class="top-bar__left">
      <div
          v-if="!useSecMenu"
          class="vue-breadcrumb"
          :class="[{ 'vue-breadcrumb--active': isCollapse }]">
        <i
            class="icon-navicon"
            @click="setCollapse"></i>
      </div>
    </div>
    <div class="top-bar__right">
      <el-menu class="navbar" mode="horizontal">
        <el-dropdown>
        <span class="el-dropdown-link">
          {{ userInfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span @click="showDiaLog" style="display:block;">修改个人信息</span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="logout" style="display:block;">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-menu>
      <el-dialog title="修改个人信息" :visible.sync="dialogVisible" size="tiny" style="position:fixed;top: 0; left: 0; right: 0; bottom: 0;z-index: 99999999;">
        <el-form :model="info" label-width="100px" :rules="addRules">
<!--          <el-form-item label="上传头像：" prop="photo">-->
<!--            <el-upload-->
<!--                class="avatar-uploader"-->
<!--                action="#"-->
<!--                ref="upload"-->
<!--                list-type="picture-card"-->
<!--                :limit="limit"-->
<!--                :http-request="fnUploadRequest"-->
<!--                :on-success="handleAvatarSuccess"-->
<!--                :before-upload="beforeAvatarUpload">-->
<!--              <el-button size="small" type="primary">点击上传</el-button>-->
<!--              <img v-if="imageUrl" :src="imageUrl" class="avatar">-->
<!--            </el-upload>-->
<!--          </el-form-item>-->
          <el-form-item label="用户名" prop="userName">
            <el-input  style="width:300px" v-model="info.userName" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input  style="width:300px" v-model="info.name" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input style="width:300px" v-model="info.mobile" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="原始密码" prop="oldPwd">
            <el-input style="width:300px" v-model="info.oldPwd"  auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPwd">
            <el-input style="width:300px" v-model="info.newPwd"  auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="newPwdAg">
            <el-input style="width:300px" v-model="info.newPwdAg"  auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="btnOk">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { validateNull } from '@/util/validate'
import { useSecMenu } from '@/config'
import { changePassword } from '@/api/sys/user'

// const OSS = require('ali-oss')
// const client = new OSS({
//   region: 'oss-cn-shanghai',
//   endpoint: 'oss-cn-shanghai.aliyuncs.com',
//   accessKeyId: '',
//   accessKeySecret: '',
//   bucket: ''
// })

export default {
  components: {},
  name: 'Top',
  data () {
    return {
      // 图片数量限制
      limit: 1,
      info: {
        headSculpture: '',
        userName: '',
        name: '',
        mobile: '',
        oldPwd: '',
        newPwd: '',
        newPwdAg: ''
      },
      dialogVisible: false,
      useSecMenu: useSecMenu,
      addRules: {
        newPwd: [{ required: true, message: '请输入密码', trigger: 'blur' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const passwordreg = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}/
              if (!passwordreg.test(value)) {
                callback(new Error('密码必须大于8位，由大小写字母、数字、特殊字符组成'))
              } else {
                callback()
              }
            }
          }],
        newPwdAg: [{ required: true, message: '请确认密码', trigger: 'blur' }],
        oldPwd: [{ required: true, message: '请输入原始密码', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created () {
  },
  mounted () {
  },
  computed: {
    ...mapState({}),
    ...mapGetters([
      'userInfo',
      'isCollapse'
    ])
  },
  methods: {
    cancel () {
      this.dialogVisible = false
      this.info = {}
    },
    showDiaLog () {
      this.dialogVisible = true
      this.info.userName = this.userInfo.username
      this.info.name = this.userInfo.name
      this.info.mobile = this.userInfo.mobile
    },
    btnOk () {
      if (this.info.newPwd !== this.info.newPwdAg) {
        this.$message({
          message: '两次新密码不一致',
          type: 'warning'
        })
      } else {
        changePassword(this.info.userName, this.info.name, this.info.mobile, this.info.oldPwd, this.info.newPwd).then(res => {
          console.log(res)
          if (res.success) {
            this.dialogVisible = false
            // 修改成功后重新登录
            this.$store.dispatch('LogOut').then(() => {
              this.$router.push({ path: '/login' })
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }
    },
    validateNull (val) {
      return validateNull(val)
    },
    setCollapse () {
      this.$store.commit('SET_COLLAPSE')
    },
    goInfo () {
      this.$router.push('/')
    },
    logout () {
      this.$confirm('退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/login' })
        })
      })
    },
    // async fnUploadRequest (options) {
    //   try {
    //     const file = options.file // 拿到 file
    //     const fileName = file.name.substr(0, file.name.lastIndexOf('.'))
    //     const date = new Date().getTime()
    //     const fileNames = `${date}_${fileName}` // 拼接文件名，保证唯一，这里使用时间戳+原文件名
    //     // 上传文件,这里是上传到OSS的文件夹下
    //     client.put(fileNames, file).then(res => {
    //       if (res.res.statusCode === 200) {
    //         // 把上传成功的值保存到imageUrl中
    //         this.imageUrlList.push(res.url)
    //         options.onSuccess(res)
    //       } else {
    //         options.onError('上传失败')
    //       }
    //     })
    //   } catch (e) {
    //     options.onError('上传失败')
    //   }
    // },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'

      if (!(isJPG || isPNG)) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式!')
      }
      if (isJPG) {
        return isJPG
      } else {
        return isPNG
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-bar__right {
  span {
    font-size: 15px;
    margin-right: 5px;
  }
  .el-form-item {
    margin-bottom: 30px;
  }
}
</style>
