import { menuDefault } from '@/config'

const common = {
  state: {
    menuDefault: menuDefault,
    isCollapse: false

  },
  mutations: {
    SET_COLLAPSE: (state) => {
      state.isCollapse = !state.isCollapse
      console.log(state.isCollapse)
    }
  }
}
export default common
