<template>
  <div class="vue-sidebar sec-sidebar">
    <logo></logo>
    <div class="start">
      <!-- 一级菜单 -->
      <div class="menu-first">
        <el-scrollbar style="height:100%">
          <div v-if="validateNull(menu)" class="vue-sidebar--tip">没有发现菜单</div>
          <el-menu
              v-else
              class="sec-bar"
              :show-timeout="200"
              @open="go"
              vue-router
              :default-active="index"
              unique-opened>
            <template v-for="(item,i1) in menu">
            <el-submenu
              v-show="validRoles(item)"
              @click="open(item)"
              :class="{'is-active':validActive(item)}"
              :key="item[labelKey]"
              :index="`${i1}`"
              v-if="item.children.length > 0"
            >
              <template slot="title">
                <i :class="item[iconKey]"/>
                <span>{{ item[labelKey] }}</span>
              </template>
              <template
                  v-for="(m, i2) in item.children">
                <el-menu-item-group
                  :key="m[labelKey]"
                  :title="m[labelKey]"
                  v-if="m.children.length > 0"
                >
                  <template v-for="(s,i3) in m.children">
                  <el-menu-item
                    :key="s[labelKey]"
                    @click="open(s)"
                    v-if="!s.showDashboard && !s.logicPage"
                    :index="`${i1}-${i2}-${i3}`">
                    <template slot="title">
                      <span>{{ s[labelKey] }}</span>
                    </template>
                  </el-menu-item>
                  </template>
                </el-menu-item-group>
                <el-menu-item
                    :key="m[labelKey]"
                    @click="open(m)"
                    v-else-if="!m.showDashboard && !m.logicPage"
                    :index="i1 + '-' + i2">
                  <template slot="title">
                    <span>{{ m[labelKey] }}</span>
                  </template>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item
                  :key="item[labelKey]"
                  @click="open(item)"
                  v-else-if="!item.showDashboard"
                  :index="`${i1}`">
                <template slot="title">
                  <i :class="item[iconKey]"/>
                  <span>{{ item[labelKey] }}</span>
                </template>
              </el-menu-item>
            </template>
          </el-menu>
        </el-scrollbar>
      </div>
<!--      <div class="menu-second">-->
<!--        <el-scrollbar style="height:100%">-->
<!--          <el-menu-->
<!--              class="sec-bar"-->
<!--              :default-active="nowTagValue"-->
<!--              mode="vertical"-->
<!--              :show-timeout="200">-->
<!--            <template-->
<!--                v-for="subMenu in subMenus">-->
<!--              <el-menu-item-->
<!--                  v-show="validRoles(subMenu)"-->
<!--                  v-if="validateNull(subMenu[childrenKey])"-->
<!--                  :index="subMenu[pathKey]"-->
<!--                  :key="subMenu[labelKey]"-->
<!--                  @click="open(subMenu)">-->
<!--                <span slot="title">{{ subMenu[labelKey] }}</span>-->
<!--              </el-menu-item>-->
<!--              <el-menu-item-group-->
<!--                  v-else-->
<!--                  v-show="validRoles(subMenu)"-->
<!--                  :key="subMenu[labelKey]">-->
<!--                <template slot="title">{{ subMenu[labelKey] }}</template>-->
<!--                <template v-for="sunMenu in subMenu.children">-->
<!--                  <el-menu-item-->
<!--                      v-show="validRoles(sunMenu)"-->
<!--                      :index="sunMenu[pathKey]"-->
<!--                      @click="open(sunMenu)"-->
<!--                      :key="sunMenu[labelKey]"-->
<!--                  >-->
<!--                    <span slot="title">{{ sunMenu[labelKey] }}</span>-->
<!--                  </el-menu-item>-->
<!--                </template>-->
<!--              </el-menu-item-group>-->
<!--            </template>-->
<!--          </el-menu>-->
<!--        </el-scrollbar>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logo from '../logo'
import { validateNull } from '@/util/validate'
import { newWin } from '@/util'

export default {
  name: 'SecBar',
  components: { logo },
  data () {
    return {
      subMenus: [],
      index: 0,
      activeItemId: null
    }
  },
  created () {
    this.$store.dispatch('GetMenu').then(data => {
      if (data.length === 0) return
      data.forEach(item => {
        if (this.validActive(item)) {
          this.subMenus = item.children
        }
      })
      this.$router.$vueRouter.formatRoutes(data, true)
    })
  },
  computed: {
    ...mapGetters(['menu', 'menuDefault', 'isCollapse']),
    nowTagValue: function () {
      return this.$router.$vueRouter.getValue(this.$route)
    },
    labelKey () {
      return this.menuDefault.props.label
    },
    pathKey () {
      return this.menuDefault.props.path
    },
    iconKey () {
      return this.menuDefault.props.icon
    },
    childrenKey () {
      return this.menuDefault.props.children
    }
  },
  methods: {
    go (e) {
      this.index = e
      for (const item of this.menu[e].children) {
        if (item.showDashboard === 1) {
          this.$router.$vueRouter.group = this.menu[e].group
          this.$router.$vueRouter.meta = this.menu[e].meta
          this.$router.push({
            path: this.$router.$vueRouter.getPath({
              name: this.menu[e].name,
              src: this.menu[e].path + '/index'
            })
          })
          break
        }
      }
    },
    selectFirst (e) {
      console.log(e)
    },
    validateNull (val) {
      return validateNull(val)
    },
    validActive (item) {
      const list = []
      item.children.forEach(c => {
        list.push(c)
        list.push(...c.children)
      })
      return (validateNull(this.activeItemId) || this.activeItemId === item.id) &&
          (list || []).some(ele =>
            this.$route.path.includes(ele.path)
          )
    },
    validRoles (item) {
      if (!item.display) {
        return false
      }
      item.meta = item.meta || {}
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true
    },
    chooseItem (item) {
      // item.path += '/index'
      // this.open(item)
      this.activeItemId = item.id
      this.subMenus = item.children
    },
    open (item) {
      if (item.open && item.path && item.path.startsWith('http')) {
        newWin(item.path)
        return false
      }
      this.$router.$vueRouter.group = item.group
      this.$router.$vueRouter.meta = item.meta
      this.$router.push({
        path: this.$router.$vueRouter.getPath({
          name: item[this.labelKey],
          src: item[this.pathKey]
        }),
        query: item.query
      })
    }
  }
}
</script>

