import { request, get } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/sys/menu/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const remove = (ids) => {
  return request({
    url: '/sys/menu/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const add = (row) => {
  return request({
    url: '/sys/menu/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/sys/menu/submit',
    method: 'post',
    data: row
  })
}

export const getMenu = (id) => {
  return request({
    url: '/sys/menu/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const tree = (category) => get('/sys/menu/tree', { category })

export const treeNew = (code) => get('/sys/menu/treeNew', { code })

export const getLazyList = (parentId, params) => {
  return request({
    url: '/sys/menu/lazy-list',
    method: 'get',
    params: {
      ...params,
      parentId
    }
  })
}

export const getLazyMenuList = (parentId, params) => {
  return request({
    url: '/sys/menu/lazy-menu-list',
    method: 'get',
    params: {
      ...params,
      parentId
    }
  })
}
